































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  IHeaderCell,
  IOptions,
  ITableCell,
  IPickupTimeStatusMapper,
} from '@/data/infrastructures/misc/interfaces/pickupTimeManagement'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import {
  PickupTimeSortFields,
  PickupTimeSortType,
  PickupTimeStatus,
} from '@/app/infrastructures/misc/Constants/pickupTimeManagement'
import Badge from '@/app/ui/components/Badge/index.vue'
import controller from '@/app/ui/controllers/PickupTimeManagementController'
import { Utils } from '@/app/infrastructures/misc'
import { PickupTime } from '@/domain/entities/PickupTimeManagement'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalConfirm from '../components/Modals/ModalConfirm/index.vue'

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    AscendingIcon,
    Badge,
    LoadingOverlay,
    ModalConfirm,
  },
})
export default class PickupTimeManagementList extends Vue {
  controller = controller

  enumSelectedSort = PickupTimeSortType
  enumSortFields = PickupTimeSortFields
  enumPickupTimeStatus = PickupTimeStatus

  selectedSort: PickupTimeSortType | null = null

  modalConfirmDeleteVisible = false
  deletedId?: number = undefined
  deletedRoute = ''

  statusOptions: IOptions[] = [
    {
      label: 'Semua',
      value: 'ALL',
    },
    {
      label: 'Menunggu',
      value: 'WAITING',
    },
    {
      label: 'Aktif',
      value: 'ACTIVE',
    },
    {
      label: 'Kadaluarsa',
      value: 'EXPIRED',
    },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    search: '',
    status: this.statusOptions[0],
  }

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Kota', '160px'),
    this.headerCellMapper('Rute', '160px'),
    this.headerCellMapper('Periode Mulai', '170px'),
    this.headerCellMapper('Periode Berakhir', '170px'),
    this.headerCellMapper('Status', '152px'),
    this.headerCellMapper('Atur', '180px'),
  ]

  listData: ITableCell[][] = []

  created(): void {
    this.fetchList(true)
  }

  get params(): Record<string, string | number> {
    return {
      timezone: encodeURIComponent(
        '+07:00'
      ),
      keyword: this.parameters.search,
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      status: this.parameters.status.value,
      lang: 'ID',
      sortBy: this.selectedSort ? this.selectedSort.split('-')[0] : '',
      sortDirection: this.selectedSort
        ? this.selectedSort.split('-')[1].toUpperCase()
        : '',
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getPickupTimes(this.params)
  }

  public onSearch = Utils.debounce(() => {
    this.fetchList(true)
  }, 500)

  public onFilter(value: IOptions): void {
    if (!value) {
      this.parameters.status = this.statusOptions[0]
    }

    this.fetchList(true)
  }

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchList(true)
  }

  public onTableSort(sortType: PickupTimeSortFields): void {
    switch (sortType) {
      case PickupTimeSortFields.CITY:
        if (this.selectedSort === PickupTimeSortType.CITY_ASC) {
          this.selectedSort = PickupTimeSortType.CITY_DESC
        } else if (this.selectedSort === PickupTimeSortType.CITY_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = PickupTimeSortType.CITY_ASC
        }
        break

      case PickupTimeSortFields.ROUTE:
        if (this.selectedSort === PickupTimeSortType.ROUTE_ASC) {
          this.selectedSort = PickupTimeSortType.ROUTE_DESC
        } else if (this.selectedSort === PickupTimeSortType.ROUTE_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = PickupTimeSortType.ROUTE_ASC
        }
        break

      case PickupTimeSortFields.PERIODE_START:
        if (this.selectedSort === PickupTimeSortType.PERIODE_START_ASC) {
          this.selectedSort = PickupTimeSortType.PERIODE_START_DESC
        } else if (
          this.selectedSort === PickupTimeSortType.PERIODE_START_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = PickupTimeSortType.PERIODE_START_ASC
        }
        break

      case PickupTimeSortFields.PERIODE_END:
        if (this.selectedSort === PickupTimeSortType.PERIODE_END_ASC) {
          this.selectedSort = PickupTimeSortType.PERIODE_END_DESC
        } else if (this.selectedSort === PickupTimeSortType.PERIODE_END_DESC) {
          this.selectedSort = null
        } else {
          this.selectedSort = PickupTimeSortType.PERIODE_END_ASC
        }
        break
    }

    this.fetchList(true)
  }

  public onConfirmDelete(id: number, route: string): void {
    this.deletedId = id
    this.deletedRoute = route
    this.modalConfirmDeleteVisible = true
  }

  public onDelete(): void {
    this.controller.deleteDynamicPickupTime(<number>this.deletedId)
    this.modalConfirmDeleteVisible = false
  }

  public pickupTimeStatusMapper(status: string): IPickupTimeStatusMapper {
    switch (status) {
      case this.enumPickupTimeStatus.ACTIVE:
        return {
          text: 'Aktif',
          color: 'success',
        }
      case this.enumPickupTimeStatus.WAITING:
        return {
          text: 'Menunggu',
          color: 'warning',
        }
      default:
        return {
          text: 'Kadaluarsa',
          color: 'error-1',
        }
    }
  }

  private periodeMapper(value: string): string {
    if (value !== 'Selamanya') {
      return Utils.formatDateWithIDLocale(value, 'dddd, DD MMM YYYY')
    }

    return value || '-'
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    isDefault = false,
    routes?: string
  ): ITableCell {
    return {
      value,
      isDefault,
      routes,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.pickupTimeData')
  onPickupTimeDataChange(data: PickupTime[]): void {
    this.listData = data.map((pickup, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1),
        '60px'
      ),
      this.tableCellMapper(pickup.city || '-', '160px'),
      this.tableCellMapper(pickup.route || '-', '160px'),
      this.tableCellMapper(
        this.periodeMapper(<string>pickup.periodeStart),
        '170px'
      ),
      this.tableCellMapper(
        this.periodeMapper(<string>pickup.periodeEnd),
        '170px'
      ),
      this.tableCellMapper(<string>pickup.status, '152px'),
      this.tableCellMapper(
        <number>pickup.id,
        '180px',
        pickup.city === 'Default',
        pickup.route
      ),
    ])
  }

  @Watch('controller.isDeleteDynamicPickupSuccess')
  onIsDeleteDynamicPickupSuccess(data: boolean): void {
    if (data) {
      this.fetchList(true)
      this.controller.setIsDeleteDynamicPickupSuccess(false)
      this.deletedId = undefined
      this.deletedRoute = ''
    }
  }
}
